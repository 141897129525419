export const generateLineChartLabel = (year: number, month: number, count: number = 12) => {
    const curentDateUtc = new Date(Date.UTC(year, month, 1));
    let barChartLabels: any[] = [];
    for (let i = 0; i < count; i++) {
        const minus = i === 0 ? 0 : 1;
        curentDateUtc.setMonth(curentDateUtc.getMonth() - minus);
        //const curentDateUtc = new Date()
        const date = new Date(Date.UTC(curentDateUtc.getFullYear(), curentDateUtc.getMonth(), 1));
        barChartLabels.push(date.toLocaleString('default', { month: 'short' }));
    }
    barChartLabels = barChartLabels.reverse();
    return barChartLabels;
};

export const round = (num, decimalPlaces = 0): number => {
    // @ts-ignore
    num = Math.round(num + 'e' + decimalPlaces);
    return Number(num + 'e' + -decimalPlaces);
};
export function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'long' });
}
