import { Module } from 'vuex';
import { Api } from '@/service/Api';
import { getEnviroAverages, getAreaEnviro } from './queries/insightsQuery';
import { useStore } from 'vuex';
import { round, getMonthName } from '@/commons/utils';

interface EnviroAverages {
    avgCo2: number | string;
    avgEci: number;
    avgHumidity: number | string;
}
interface EnviroState {
    enviroUpdate: number;
    enviroCo2Area: any[];
    enviroHumidityArea: any[];
    enviroAverages: EnviroAverages;
    lineChartData: any[];
    enviroDetail: {};
    enviroDetailUpdate: number;
}

export const enviroStore: Module<EnviroState, any> = {
    namespaced: true,
    state: () => ({
        enviroUpdate: 0,
        enviroCo2Area: [],
        enviroHumidityArea: [],
        enviroAverages: {
            avgCo2: 'NaN',
            avgEci: 0,
            avgHumidity: 0,
        },
        lineChartData: [],
        enviroDetail: {},
        enviroDetailUpdate: 0,
    }),
    mutations: {
        updateEnviroUpdate(state, enviroUpdate) {
            state.enviroUpdate = enviroUpdate;
        },
        updateEnviroCo2Area(state, enviroCo2Area) {
            state.enviroCo2Area = enviroCo2Area;
        },
        updateEnviroHumidityArea(state, enviroHumidityArea) {
            state.enviroHumidityArea = enviroHumidityArea;
        },
        updateEnviroAverages(state, enviroAverages) {
            state.enviroAverages = enviroAverages;
        },
        updateLineChartData(state, lineChartData) {
            state.lineChartData = lineChartData;
        },
        updateEnviroDetail(state, enviroDetail) {
            state.enviroDetail = enviroDetail;
        },
        updateEnviroDetailUpdate(state, enviroDetailUpdate) {
            state.enviroDetailUpdate = enviroDetailUpdate;
        },
    },
    actions: {
        async loadData({ commit }, params: any) {
            this.dispatch('showhideLoader', true);
            const buildingId = await this.getters['dashboard/getBuildingId'];
            const tenantId = await this.getters['dashboard/getTenantId'];

            let dataType: string = 'BUILDING';

            if (params.hasOwnProperty('floor') && params.floor !== 'all') {
                dataType = 'FLOOR';
            }

            const variables: any = {
                buildingId: buildingId,
                from: params.from,
                to: params.to,
                tenantId: tenantId,
                businessHours: '915',
                spaceType: dataType,
                floor: params.floor && params.floor !== 'all' ? params.floor : '',
            };
            const enviroResult: any = await Api.query(
                getEnviroAverages(variables.floor),
                variables,
            );
            if (enviroResult.data && enviroResult.data.getEnviroAverages) {
                const enviroUpdate = new Date().getTime();
                const enviroData: any = enviroResult.data.getEnviroAverages;
                const enviroCo2Area: any[] = [];
                const enviroHumidityArea: any[] = [];

                commit('updateEnviroAverages', {
                    avgCo2: enviroData.avgCo2 !== null ? enviroData.avgCo2 : 'NaN',
                    avgEci: enviroData.avgEci !== null ? enviroData.avgEci : 0,
                    avgHumidity: enviroData.avgHumidity ? enviroData.avgHumidity : 'NaN',
                });
                // lineChartData
                const lineChartData =
                    params.floor && params.floor !== 'all'
                        ? enviroData.floorValues
                        : enviroData.buildingValues;
                commit('updateLineChartData', lineChartData);
                enviroData.areaValues.forEach(item => {
                    const co2Item = {
                        first: item.co2Excellent !== null ? round(item.co2Excellent, 1) : 0,
                        second: item.co2Good !== null ? round(item.co2Good, 1) : 0,
                        third: item.co2Poor !== null ? round(item.co2Poor, 1) : 0,
                        spaceName: item.spaceName,
                        id: item.spaceId,
                    };
                    enviroCo2Area.push(co2Item);
                    const humadityItem = {
                        first:
                            item.humidityBelowRange !== null
                                ? round(item.humidityBelowRange, 1)
                                : 0,
                        second: item.humidityInRange !== null ? round(item.humidityInRange, 1) : 0,
                        third:
                            item.humidityAboveRange !== null
                                ? round(item.humidityAboveRange, 1)
                                : 0,
                        spaceName: item.spaceName,
                        id: item.spaceId,
                    };
                    enviroHumidityArea.push(humadityItem);
                });
                const sortEnviroCo2 = (a, b) => {
                    if (a.first > b.first) {
                        return -1;
                    } else if (a.first < b.first) {
                        return 1;
                    }
                    return 0;
                };
                const sortHumidityArea = (a, b) => {
                    if (a.second > b.second) {
                        return -1;
                    } else if (a.second < b.second) {
                        return 1;
                    }
                    return 0;
                };
                enviroCo2Area.sort(sortEnviroCo2);
                enviroHumidityArea.sort(sortHumidityArea);
                commit('updateEnviroCo2Area', enviroCo2Area);
                commit('updateEnviroHumidityArea', enviroHumidityArea);
                commit('updateEnviroUpdate', enviroUpdate);
            }
            this.dispatch('showhideLoader', false);
        },
        async loadEnviroDetail({ commit }, spaceId) {
            const store = useStore();
            const buildingId = await this.getters['dashboard/getBuildingId'];
            const tenantId = await this.getters['dashboard/getTenantId'];
            const curentDate = new Date();
            const endDay = new Date(
                Date.UTC(curentDate.getUTCFullYear(), curentDate.getUTCMonth() - 1, 1),
            );
            const startDay = new Date(
                Date.UTC(curentDate.getUTCFullYear(), curentDate.getUTCMonth() - 12, 1),
            );
            const variables: any = {
                buildingId: buildingId,
                tenantId: tenantId,
                spaceId: spaceId,
                businessHours: '915',
                from: startDay.getTime(),
                to: endDay.getTime(),
            };
            //console.log(variables);
            store.dispatch('showhideLoader', true);
            const enviroResponse: any = await Api.query(getAreaEnviro(), variables);
            //console.log(enviroResponse);
            if (enviroResponse.data && enviroResponse.data.getAreaEnviro) {
                const values = enviroResponse.data.getAreaEnviro.values;
                const resultData = {};
                const months = Array();
                const co2Data = Array();
                const co2Excellent = Array();
                const co2Good = Array();
                const co2Poor = Array();
                const humidityData = Array();
                const humidityBelowRange = Array();
                const humidityInRange = Array();
                const humidityAboveRange = Array();

                if (Array.isArray(values)) {
                    for (let i = 0; i < values.length; i++) {
                        const item = values[i];
                        resultData[item.year + '_' + item.month] = item;
                    }
                    for (let i = 0; i < 12; i++) {
                        const date = new Date(
                            Date.UTC(startDay.getUTCFullYear(), startDay.getUTCMonth() + i, 1),
                        );
                        months.push(date.toLocaleString('default', { month: 'short' }));
                        const key = date.getUTCFullYear() + '_' + (date.getUTCMonth() + 1);
                        if (resultData.hasOwnProperty(key)) {
                            const item = resultData[key];
                            co2Excellent.push(item.co2Excellent);
                            co2Good.push(item.co2Good);
                            co2Poor.push(item.co2Poor);
                            humidityBelowRange.push(item.humidityBelowRange);
                            humidityInRange.push(item.humidityInRange);
                            humidityAboveRange.push(item.humidityAboveRange);
                        } else {
                            co2Excellent.push(null);
                            co2Good.push(null);
                            co2Poor.push(null);
                            humidityBelowRange.push(null);
                            humidityInRange.push(null);
                            humidityAboveRange.push(null);
                        }
                    }
                }
                co2Data.push(co2Excellent);
                co2Data.push(co2Good);
                co2Data.push(co2Poor);
                humidityData.push(humidityBelowRange);
                humidityData.push(humidityInRange);
                humidityData.push(humidityAboveRange);

                const enviroDetail = {
                    bestCo2: enviroResponse.data.getAreaEnviro.bestCo2,
                    bestHumidity: enviroResponse.data.getAreaEnviro.bestHumidity,
                    worstCo2: enviroResponse.data.getAreaEnviro.worstCo2,
                    worstHumidity: enviroResponse.data.getAreaEnviro.worstHumidity,
                    months: months,
                    co2Data: co2Data,
                    humidityData: humidityData,
                    spaceName: enviroResponse.data.getAreaEnviro.spaceName
                        ? enviroResponse.data.getAreaEnviro.spaceName
                        : 'space',
                };
                commit('updateEnviroDetail', enviroDetail);
                commit('updateEnviroDetailUpdate', new Date().getTime());
            }
            store.dispatch('showhideLoader', false);
        },
    },
    getters: {
        getEnviroUpdate: state => state.enviroUpdate,
        getEnviroCo2Area: state => state.enviroCo2Area,
        getEnviroHumidityArea: state => state.enviroHumidityArea,
        getEnviroAverages: state => state.enviroAverages,
        getLineChartData: state => state.lineChartData,
        getEnviroDetail: state => state.enviroDetail,
        getEnviroDetailUpdate: state => state.enviroDetailUpdate,
    },
};
