import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { Amplify } from 'aws-amplify';
import { AuthInsights } from '@/commons/authInsights';
import { install } from 'resize-observer';

install();
Amplify.configure({
    Auth: {
        region: process.env.VUE_APP_REGION,
        userPoolId: process.env.VUE_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
        mandatorySignIn: true,
    },
});
store.dispatch('loadApp/loadModules', router).then(async result => {
    const app = createApp(App).use(store).use(router);
    AuthInsights.init(store);
    app.mount('#app');
});
