import { Module } from 'vuex';
import { Api } from '@/service/Api';
import { DeskBookingParams } from './../types/types';
import { getDeskBookings, getDeskBookingsByDesk } from './queries/bookingQuery';
import { round } from './../common/deskBookiingUtils';

interface DeskBookingState {
    monthlyValues: any[];
    monthlyAvereges: {};
    byDeskValues: any[];
    deskBookingCardData: any;
}
export const deskBookingStore: Module<DeskBookingState, any> = {
    namespaced: true,
    state: () => ({
        monthlyValues: [],
        monthlyAvereges: {
            dayBookingsPercentage: 0,
            amBookingsPercentage: 0,
            pmBookingsPercentage: 0,
        },
        byDeskValues: [],
        deskBookingCardData: {
            mostBookedDesk: {
                name: 'NaN',
                value: 'NaN',
            },
            mostBookings: {
                date: 'NaN',
                value: 'NaN',
            },
            canceledBookings: 'NaN',
        },
    }),
    mutations: {
        updateMonthlyValues(state, monthlyValues) {
            state.monthlyValues = monthlyValues;
        },
        updateMonthlyAvereges(state, monthlyAvereges) {
            state.monthlyAvereges = monthlyAvereges;
        },
        updateByDeskValues(state, byDeskValues) {
            state.byDeskValues = byDeskValues;
        },
        updateDeskBookingCardData(state, deskBookingCardData) {
            state.deskBookingCardData = deskBookingCardData;
        },
    },
    actions: {
        async loadData({ commit }, params: DeskBookingParams) {
            try {
                this.dispatch('showhideLoader', true);
                const buildingId = await this.getters['dashboard/getBuildingId'];
                const tenantId = await this.getters['dashboard/getTenantId'];

                const variables: any = {
                    buildingId: buildingId,
                    tenantId: tenantId,
                    month: params.month,
                    year: params.year,
                };

                const bookingResult: any = await Api.query(getDeskBookings(), variables);
                const bookingByDeskResult: any = await Api.query(
                    getDeskBookingsByDesk(),
                    variables,
                );
                let mostBookedDesk: any = {
                    name: 'NaN',
                    value: 'NaN',
                };
                let mostBookings: any = {
                    date: 'NaN',
                    value: 'NaN',
                };
                let canceledBookings = 'NaN';

                if (bookingResult) {
                    const bookingData = bookingResult?.data.getDeskBookings;
                    canceledBookings =
                        bookingData.canceledBookings !== null
                            ? bookingData.canceledBookings
                            : 'NaN';
                    const getFormatedDate = (stringDate): string => {
                        //stringDate
                        const date = new Date(stringDate);
                        const month = date.toLocaleString('default', { month: 'long' });
                        const day = date.getDate();
                        const suffix = () => {
                            if (day === 1) {
                                return 'st';
                            } else if (day === 2) {
                                return 'nd';
                            } else if (day === 3) {
                                return 'rd';
                            } else return 'th';
                        };
                        return day + suffix() + ' of ' + month;
                    };
                    const dateMostBookings =
                        bookingData.mostBookings.date !== null
                            ? getFormatedDate(bookingData.mostBookings.date)
                            : 'NaN';
                    mostBookings = {
                        date: dateMostBookings,
                        value:
                            bookingData.mostBookings.value !== null
                                ? round(bookingData.mostBookings.value, 1)
                                : 'NaN',
                    };
                    if (bookingData.values) {
                        commit('updateMonthlyValues', bookingData.values);
                    }
                    const monthlyAvereges = {
                        dayBookingsPercentage: bookingData.dayBookingsPercentage
                            ? round(bookingData.dayBookingsPercentage, 1)
                            : 0,
                        amBookingsPercentage: bookingData.amBookingsPercentage
                            ? round(bookingData.amBookingsPercentage, 1)
                            : 0,
                        pmBookingsPercentage: bookingData.pmBookingsPercentage
                            ? round(bookingData.pmBookingsPercentage, 1)
                            : 0,
                    };
                    commit('updateMonthlyAvereges', monthlyAvereges);
                }
                if (bookingByDeskResult) {
                    const deskBookingData = bookingByDeskResult?.data.getDeskBookingsByDesk;
                    mostBookedDesk = {
                        name:
                            deskBookingData.mostBookedDesk.name !== null
                                ? deskBookingData.mostBookedDesk.name
                                : 'NaN',
                        value:
                            deskBookingData.mostBookedDesk.value !== null
                                ? round(deskBookingData.mostBookedDesk.value, 2)
                                : 'NaN',
                    };
                    if (deskBookingData.values) {
                        if (deskBookingData.values.length > 0) {
                            const dataValues = deskBookingData.values.sort((a, b) => {
                                if (
                                    Number(a.totalBookingsPercentage) >
                                    Number(b.totalBookingsPercentage)
                                ) {
                                    return -1;
                                } else if (
                                    Number(a.totalBookingsPercentage) <
                                    Number(b.totalBookingsPercentage)
                                ) {
                                    return 1;
                                }
                                return 0;
                            });
                            commit('updateByDeskValues', dataValues);
                        } else {
                            commit('updateByDeskValues', deskBookingData.values);
                        }
                    }
                }
                const deskBookingCardData = {
                    mostBookedDesk: mostBookedDesk,
                    mostBookings: mostBookings,
                    canceledBookings: canceledBookings,
                };
                commit('updateDeskBookingCardData', deskBookingCardData);
                this.dispatch('showhideLoader', false);
            } catch (e) {
                this.dispatch('showhideLoader', false);
            }
        },
    },
    getters: {
        getMonthlyValues: state => state.monthlyValues,
        getMonthlyAvereges: state => state.monthlyAvereges,
        getByDeskValues: state => state.byDeskValues,
        getDeskBookingCardData: state => state.deskBookingCardData,
    },
};
