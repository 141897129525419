import { gql } from 'graphql-tag';
export const getCurrentUserQuery = gql(`query getCurrentUser { getCurrentUser {
    email
    permissions {
      deskBooking {
        buildings {
          buildingId
          tenants
        }
      }
      enviro {
        buildings {
          buildingId
          tenants
        }
      }
      occupancy {
        buildings {
          buildingId
          tenants
        }
      }
      scopes
    }
  }
}`);
export const getCurrentUser = gql(`query getCurrentUser { getCurrentUser {
  email
    permissions {
      DeskBooking
      MonthlyEnviro
      MonthlyOccupancy
      MonthlyEnergy
      UserManagement
    }
}
}`);
export const getBuildingQuery = gql(`query getBuildings  { getBuildings  {
    items {
        address
        countryCode
        floors {
          name
          value
        }
        statistics {
          statisticType
          value
          unit
          diff
          diffUnit
          buildingId
          tenantId
          businessHours
        }
        tenants {
          imageUrl
          name
          tenantId
          dateFrom
          floors {
            floor
            floorPlanUrl
            floorPlanBgColor
          }
        }
        name
        imageUrl
        id
      }
} }`);
