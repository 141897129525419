import { Module } from 'vuex';
import modules from '@/commons/moduleLoader';
import { Router } from 'vue-router';
import { Api } from '@/service/Api';
import { getCurrentUser } from '@/service/dashBoardQuery';
import { Auth } from 'aws-amplify';
//import store from '..';
//import { Request } from '@/service/Api';

interface LoadAppState {
    loaded: boolean;
}

export const loadApp: Module<LoadAppState, any> = {
    namespaced: true,
    state: () => ({
        loaded: false,
    }),
    mutations: {
        setLoaded(state) {
            state.loaded = true;
        },
    },
    actions: {
        /**
         *
         * @@description load modules but call after user is authenticated
         * @todo add permission
         */
        loadModules({ commit }, router: Router) {
            return new Promise(async (resolve, reject) => {
                const isAuthenticated: boolean = await this.getters['auth/isAuthenticated'];
                try {
                    if (!isAuthenticated) {
                        resolve(true);
                    } else if (this.getters['loadApp/isLoaded']) {
                        resolve(true);
                    } else {
                        console.log('HERE 1', getCurrentUser)
                        const resultCurrentUser: any = await Api.query(getCurrentUser);
                        const appPermisson = {};
                        if (resultCurrentUser) {
                            console.log('HERE', resultCurrentUser)
                            Object.entries(
                                resultCurrentUser.data.getCurrentUser.permissions,
                            ).forEach(([key, value]) => {
                                if (Array.isArray(value)) {
                                    if (!appPermisson[key]) {
                                        appPermisson[key] = {};
                                    }
                                    // Array values
                                    value.forEach((val: string) => {
                                        const [tenantId, buildingId] = val.split('-', 2);
                                        if (!appPermisson[key][buildingId]) {
                                            appPermisson[key][buildingId] = {};
                                        }
                                        appPermisson[key][buildingId][tenantId] = {};
                                    });
                                } else {
                                    appPermisson[key] = value;
                                }
                            });
                        } else {
                            await Auth.signOut();
                            console.error('Error: No user found');
                            // window.location.reload();
                            reject('Error: No user found');
                        }

                        //const selectionMenu: Set<string> = new Set<string>();
                        const mainMenu = Array();
                        const menus = {};
                        const hieratchy = new Object();
                        modules.forEach(module => {
                            if (module.selectionPage) {
                                const props = Object.keys(module.selectionPage);
                                if (Array.isArray(props)) {
                                    props.forEach(prop => {
                                        const selectionMenuItem = prop;
                                        if (Array.isArray(module.selectionPage[prop])) {
                                            module.selectionPage[prop].forEach(route => {
                                                if (router !== null) {
                                                    const menuItem = {
                                                        name: route.name,
                                                        path: route.path,
                                                        statisticType: route.statisticType
                                                            ? route.statisticType
                                                            : '',
                                                        viewName: route.viewName,
                                                        hierarchyType: selectionMenuItem,
                                                        icon: route.icon,
                                                        active: false,
                                                        disabled: true,
                                                    };
                                                    if (route.typeMenu === 'main') {
                                                        mainMenu.push(menuItem);
                                                    } else {
                                                        if (!menus.hasOwnProperty(route.typeMenu)) {
                                                            menus[route.typeMenu] = Array();
                                                        }
                                                        menus[route.typeMenu].push(menuItem);
                                                    }
                                                    hieratchy[route.viewName] = selectionMenuItem;
                                                    router.addRoute({
                                                        component: route.component,
                                                        name: route.name,
                                                        path: route.path,
                                                        meta: {
                                                            requiresAuth: true,
                                                            viewName: route.viewName,
                                                            hierarchyType: selectionMenuItem,
                                                            permissionGroups:
                                                                route.permissionGroups,
                                                            typeMenu: route.typeMenu
                                                                ? route.typeMenu
                                                                : '',
                                                        },
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                            }
                        });
                        this.dispatch('auth/updatePermission', appPermisson);
                        this.dispatch('setHierarchy', hieratchy);
                        //this.dispatch('selection/loadSelectionMenu', selectionMenu);
                        this.dispatch('loadMainMenu', mainMenu);
                        this.dispatch('loadMenus', menus);
                        commit('setLoaded');
                        resolve(true);
                    }
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
    },
    getters: {
        isLoaded: state => state.loaded,
    },
};
