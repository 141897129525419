import { Auth } from 'aws-amplify';
import { Module } from 'vuex';
import { AuthInsights, HasPermissionParam } from '@/commons/authInsights';
//import { Request } from '@/service/Api';

interface AuthState {
    loginState: LoginState;
    errorMessage: string;
    permission: Object;
}
export enum LoginState {
    login,
    newpassword,
    forgotpassword,
    confirmforgot,
}

const authStateInit = (): AuthState => {
    const permission = sessionStorage.getItem('fc090baa-7fcb-4dff-9026-95bc451d4604pe');
    return {
        loginState: LoginState.login,
        errorMessage: '',
        permission: permission
            ? JSON.parse(permission)
            : {
                  deskBooking: null,
                  enviro: null,
                  occupancy: null,
                  userManagement: null,
                  scopes: null,
              },
    };
};

export const authStore: Module<AuthState, any> = {
    namespaced: true,
    state: () => authStateInit(),
    mutations: {
        setLoginState(state, loginState) {
            state.loginState = loginState;
        },
        setErrorMessage(state, message) {
            state.errorMessage = message;
        },
        updatePermission(state, permission: Object) {
            sessionStorage.setItem(
                'fc090baa-7fcb-4dff-9026-95bc451d4604pe',
                JSON.stringify(permission),
            );
            state.permission = permission;
        },
    },
    actions: {
        setErrorMessage({ commit }, message) {
            commit('setErrorMessage', message);
        },
        // TODO: rename function
        completeNewPassword({ commit }, userData) {
            commit('setErrorMessage', '');
            return new Promise((resolve, reject) => {
                Auth.completeNewPassword(userData.user, userData.newPassword)
                    .then(user => {
                        resolve(true);
                    })
                    .catch(e => {
                        commit('setErrorMessage', e);
                        reject();
                    });
            });
        },
        forgotPassword({ commit }, email) {
            commit('setErrorMessage', '');
            return new Promise((resolve, reject) => {
                Auth.forgotPassword(email)
                    .then(result => {
                        resolve(true);
                    })
                    .catch(e => {
                        commit('setErrorMessage', 'Something went wrong!');
                        reject(e);
                    });
            });
        },
        confirmForgot({ commit }, formData) {
            commit('setErrorMessage', '');
            return new Promise((resolve, reject) => {
                Auth.forgotPasswordSubmit(formData.email, formData.code, formData.password)
                    .then(result => {
                        resolve(true);
                    })
                    .catch(e => {
                        reject(e);
                    });
            });
        },
        signIn({ commit }, form: any) {
            commit('setErrorMessage', '');
            this.dispatch('showhideLoader', true);
            return new Promise((resolve, reject) => {
                Auth.signIn(form.get('username'), form.get('password'))
                    .then(async user => {
                        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                            commit('setLoginState', LoginState.newpassword);
                            this.dispatch('showhideLoader', false);
                            resolve(user);
                        } else {
                            this.dispatch('showhideLoader', false);
                            resolve(true);
                        }
                    })
                    .catch(e => {
                        this.dispatch('showhideLoader', false);
                        commit('setErrorMessage', JSON.stringify(e));
                        reject(e);
                    });
            });
        },
        updatePermission({ commit }, permission: Object) {
            commit('updatePermission', permission);
        },
        setLoginState({ commit }, loginState: LoginState) {
            commit('setLoginState', loginState);
        },
    },
    getters: {
        getErroMessage: state => state.errorMessage,
        getLoginState: state => state.loginState,
        isAuthenticated() {
            return new Promise(resolve => {
                Auth.currentAuthenticatedUser()
                    .then(() => {
                        resolve(true);
                    })
                    .catch(err => {
                        resolve(false);
                    });
            });
        },
        getPermission: state => state.permission,
        hasPermissions: () => (props: HasPermissionParam) => {
            return new Promise(resolve => {
                AuthInsights.hasPermission(props)
                    .then(res => resolve(res))
                    .catch(() => resolve(false));
            });
        },
    },
};
