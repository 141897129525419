export const generateMonth = (year: Number, locale: string = 'en-GB') => {
    const months: any[] = [];
    for (let i = 0; i < 12; i++) {
        const date = new Date(Date.UTC(year as number, i, 1));
        const item = {
            month: date.toLocaleString(locale, { month: 'short', timeZone: 'UTC' }),
            date: date,
            data: String(date.getFullYear()) + String(date.getMonth()),
            class: 'date-item',
        };
        months.push(item);
    }
    return months;
};
export const removeClasses = eventTarget => {
    eventTarget?.childNodes.forEach(el => {
        if (el.classList !== undefined) {
            el.classList.remove('first', 'last', 'selected', 'disabled', 'first-last');
        }
    });
};
export const addClases = (
    start: Date | null,
    end: Date | null,
    parentNode,
    min: Date | null,
    maxRange: number,
) => {
    const now = new Date();
    const currentDate = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1);
    if (start !== null && end === null) {
        // select start
        parentNode?.childNodes.forEach(el => {
            if (el.classList !== undefined) {
                const data = el.getAttribute('data');
                const parseData = data.match(/.{1,4}/g);
                const elDate = Date.UTC(parseData[0] as number, parseData[1], 1, 0);
                const maxRangeDate = Date.UTC(start.getFullYear(), start.getMonth() + maxRange - 1);

                if (
                    elDate > currentDate ||
                    elDate > maxRangeDate ||
                    (min && min.getTime() > elDate)
                ) {
                    el.classList.add('disabled');
                } else if (start.getTime() === elDate) {
                    el.classList.add('first');
                }
            }
        });
    } else if (start !== null && end !== null) {
        parentNode?.childNodes.forEach(el => {
            if (el.classList !== undefined) {
                const data = el.getAttribute('data');
                const parseData = data.match(/.{1,4}/g);
                const elDate = Date.UTC(parseData[0] as number, parseData[1], 1, 0);

                if (elDate > currentDate || (min && min.getTime() > elDate)) {
                    el.classList.add('disabled');
                } else {
                    el.classList.remove('disabled');
                    if (start.getTime() === elDate && end.getTime() !== elDate) {
                        el.classList.add('first');
                    } else if (start.getTime() < elDate && elDate < end.getTime()) {
                        el.classList.add('selected');
                    } else if (start.getTime() === elDate && start.getTime() === end.getTime()) {
                        el.classList.remove('first');
                        el.classList.add('first-last');
                    } else if (end.getTime() === elDate) {
                        el.classList.add('last');
                    }
                }
            }
        });
    } else if (start === null && end === null) {
        parentNode?.childNodes.forEach(el => {
            if (el.classList !== undefined) {
                const data = el.getAttribute('data');
                const parseData = data.match(/.{1,4}/g);
                const elDate = Date.UTC(parseData[0] as number, parseData[1], 1, 0);
                if (elDate > currentDate || (min && min.getTime() > elDate)) {
                    el.classList.add('disabled');
                }
            }
        });
    }
};
