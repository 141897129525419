import { gql } from 'graphql-tag';

export const getModules = () => {
    return gql(`query getModules {
        getModules {
            items {
                buildingId
                module
                tenantId
            }
        }
    }`);
};

export const userList = () => {
    return gql(`query userList {
        userList {
            items {
                email
                permissions {
                  MonthlyOccupancy
                  MonthlyEnviro
                  DeskBooking
                }
            }
        }
    }`);
};

export const updateUserPermissions = (modules: any[] = []) => {
    let dataType = '';
    let dataVariable = '';

    if (modules.length > 0) {
        modules.forEach((module: String) => {
            dataType += ', $' + module + ': [String!]';
            dataVariable += ', ' + module + ': $' + module;
        });
    }
    return gql(`mutation updateUserPermissions ($email: String!${dataType} ) {
        updateUserPermissions (email:$email${dataVariable}) {
            email
        }
    }`);
};
export const deleteUserMutation = () => {
    return gql(`mutation deleteUser ($email: String!) {
        deleteUser (email:$email) {
            message
            status
        }
    }`);
};

export const createUserMutation = () => {
    return gql(`mutation createUser ($email: String!) {
        createUser (email:$email) {
            message
            status
        }
    }`);
};
