import { Store } from 'vuex';
import router from '@/router/index';
import { toRaw } from 'vue';
export interface HasPermissionParam {
    viewName: string;
    buildingId?: string;
    tenantId?: string;
}
interface ViewPermissionParam {
    viewName: string;
    buildingId: string;
    tenants?: any[];
    findFirstTenant?: boolean;
}

class AuthInsightsClass {
    private store: Store<any> | null;
    constructor() {
        this.store = null;
    }
    public init(store: Store<any>) {
        this.store = store;
    }

    private _hasPermission(
        route,
        permission,
        hasPermissionParam: HasPermissionParam,
        tenant = true,
    ) {
        let groupPermission = false;
        if (tenant) {
            if (route.meta.permissionGroups && Array.isArray(route.meta.permissionGroups)) {
                for (let i = 0; i < route.meta.permissionGroups.length; i++) {
                    const permissionItem = route.meta.permissionGroups[i];
                }
                route.meta.permissionGroups.forEach(element => {
                    if (
                        permission[element] &&
                        permission[element][hasPermissionParam.buildingId!] &&
                        permission[element][hasPermissionParam.buildingId!][
                            hasPermissionParam.tenantId!
                        ]
                    ) {
                        groupPermission = true;
                    }
                });
            }
        } else {
            if (route.meta.permissionGroups && Array.isArray(route.meta.permissionGroups)) {
                for (let i = 0; i < route.meta.permissionGroups.length; i++) {
                    const permissionItem = route.meta.permissionGroups[i];
                }
                route.meta.permissionGroups.forEach(element => {
                    if (
                        permission[element] &&
                        permission[element][hasPermissionParam.buildingId!]
                    ) {
                        groupPermission = true;
                    }
                });
            }
        }
        return groupPermission;
    }

    public async hasPermission(hasPermissionParam: HasPermissionParam): Promise<boolean> {
        if (this.store !== null) {
            let permission = await this.store.getters['auth/getPermission'];
            if (permission) permission = { ...toRaw(permission) };
            const hierarchy = await this.store.getters['getHierarchy'];
            const routes = router.getRoutes().filter(el => {
                return el.meta.viewName && el.meta.viewName === hasPermissionParam.viewName;
            });
            const route: any = routes.length > 0 ? routes[0] : null;

            if (route === null) {
                return false;
            }
            if (hierarchy[hasPermissionParam.viewName] === 'tenant') {
                if (!hasPermissionParam.buildingId || !hasPermissionParam.tenantId) {
                    return false;
                } else if (this._hasPermission(route, permission, hasPermissionParam)) {
                    return true;
                }
            } else if (hierarchy[hasPermissionParam.viewName] === 'building') {
                if (!hasPermissionParam.buildingId) {
                    return false;
                } else if (this._hasPermission(route, permission, hasPermissionParam, false)) {
                    return true;
                }
            } else if (hierarchy[hasPermissionParam.viewName] === 'independent') {
                if (route.meta.permissionGroups && Array.isArray(route.meta.permissionGroups)) {
                    let hasPermission = false;
                    route.meta.permissionGroups.forEach(itemPermission => {
                        if (permission.hasOwnProperty(itemPermission)) {
                            hasPermission = true;
                        }
                    });
                    return hasPermission;
                }
                return false;
            }
        }
        return false;
    }
    public async havePesrmissionForView(params: ViewPermissionParam): Promise<boolean> {
        if (this.store) {
            const hierarchy = await this.store.getters['getHierarchy'];
            const currentTenantId: string = await this.store.getters['dashboard/getTenantId'];
            if (hierarchy) {
                if (hierarchy[params.viewName] === 'tenant') {
                    let hasPermission: boolean = false;
                    if (currentTenantId) {
                        hasPermission = await AuthInsights.hasPermission({
                            viewName: params.viewName,
                            buildingId: params.buildingId,
                            tenantId: currentTenantId,
                        });
                    }
                    if (!hasPermission && params.findFirstTenant) {
                        // find first available tenant
                        if (Array.isArray(params.tenants)) {
                            for (let i = 0; i < params.tenants.length; i++) {
                                hasPermission = await AuthInsights.hasPermission({
                                    viewName: params.viewName,
                                    buildingId: params.buildingId,
                                    tenantId: params.tenants[i].tenantId,
                                });
                                if (hasPermission) {
                                    this.store.dispatch(
                                        'dashboard/setTenantId',
                                        params.tenants[i].tenantId,
                                    );
                                    break;
                                }
                            }
                        } else {
                            hasPermission = false;
                        }
                    }
                    return hasPermission;
                } else if (hierarchy[params.viewName] === 'building') {
                    const hasPermission = await AuthInsights.hasPermission({
                        viewName: params.viewName,
                        buildingId: params.buildingId,
                    });
                    return hasPermission;
                } else if (hierarchy[params.viewName] === 'independent') {
                    const hasPermission = await AuthInsights.hasPermission({
                        viewName: params.viewName,
                    });
                    return hasPermission;
                }
            } else return false;
            return false;
        }
        return false;
    }
}
export const AuthInsights = new AuthInsightsClass();
