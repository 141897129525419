import { Module } from 'vuex';
import { Api } from '@/service/Api';
import { getBuildingQuery } from '@/service/dashBoardQuery';
import { AnalyticsOptions } from '@aws-amplify/analytics/lib-esm/types';

interface DashboardState {
    selectedBuildingId?: string;
    buildings: Array<AnalyticsOptions>;
    buildingId?: string;
    buildingName: string;
    tenantId?: string;
    buildingList: {};
    buildingListArray: any[];
    tenantsList: {};
    buildingFloors: {};
}

const initialState = (): DashboardState => {
    const buildingId = sessionStorage.getItem('buildingId');
    const buildingName = sessionStorage.getItem('buildingName');
    const tenantId = sessionStorage.getItem('tenantId');
    const tenantList = sessionStorage.getItem('3b9381b0-d466-42f9-84d5-bcaca3bd780a');
    const buildingList = sessionStorage.getItem('caa1372d-b93f-4c52-b45f-f577733a03a9');
    const buildingListData = buildingList ? JSON.parse(buildingList) : {};
    const buildingListArray = Object.keys(buildingListData).map(key => buildingListData[key]);
    const buildingFloors = sessionStorage.getItem('950bbbd7-1f37-4d3b-9ec2-ace7472a23ba');
    return {
        selectedBuildingId: '',
        buildings: [],
        buildingId: buildingId ? buildingId : '',
        buildingName: buildingName ? buildingName : 'buildingName',
        tenantId: tenantId ? tenantId : '',
        buildingList: buildingList ? JSON.parse(buildingList) : {},
        tenantsList: tenantList ? JSON.parse(tenantList) : {},
        buildingListArray: buildingListArray,
        buildingFloors: buildingFloors ? JSON.parse(buildingFloors) : {},
    };
};

export const dashboardStore: Module<DashboardState, any> = {
    namespaced: true,
    state: initialState(),
    mutations: {
        updateBuildings(state, buildings) {
            state.buildings = buildings;
        },
        setBuildingId(state, buildingId: string) {
            sessionStorage.setItem('buildingId', buildingId);
            const buildingName = state.buildingList[buildingId]
                ? state.buildingList[buildingId].name
                : 'Building Name';
            sessionStorage.setItem('buildingName', buildingName);
            state.buildingName = buildingName;
            state.buildingId = buildingId;
        },
        setTenantId(state, tenantId) {
            sessionStorage.setItem('tenantId', tenantId);
            state.tenantId = tenantId;
        },
        setTenantsList(state, tenantsList) {
            sessionStorage.setItem(
                '3b9381b0-d466-42f9-84d5-bcaca3bd780a',
                JSON.stringify(tenantsList),
            );
            state.tenantsList = tenantsList;
        },
        setBuildingList(state, buildingList) {
            sessionStorage.setItem(
                'caa1372d-b93f-4c52-b45f-f577733a03a9',
                JSON.stringify(buildingList),
            );
            state.buildingList = buildingList;
            state.buildingListArray = Object.keys(buildingList).map(key => buildingList[key]);
        },
        setBuildingFloors(state, buildingFloors) {
            sessionStorage.setItem(
                '950bbbd7-1f37-4d3b-9ec2-ace7472a23ba',
                JSON.stringify(buildingFloors),
            );
            state.buildingFloors = buildingFloors;
        },
    },
    actions: {
        async getBuildings({ commit }) {
            const result = await Api.query(getBuildingQuery, {});
            if (result) {
                const data: any = result.data;
                const buildingList = new Object();
                const tenantsList = new Object();
                const buildingFloors = new Object();
                if (Array.isArray(data.getBuildings.items)) {
                    data.getBuildings.items.forEach(building => {
                        const item = {
                            id: building.id,
                            name: building.name,
                            img: building.imageUrl,
                        };
                        buildingList[building.id] = item;
                        if (Array.isArray(building.tenants)) {
                            tenantsList[building.id] = [];
                            building.tenants.forEach(tenant => {
                                const tenantItem = {
                                    id: tenant.tenantId,
                                    name: tenant.name,
                                    dateFrom: tenant.dateFrom,
                                    floors: {},
                                };
                                if (tenant.floors && Array.isArray(tenant.floors)) {
                                    tenant.floors.forEach(floor => {
                                        if (floor.floorPlanUrl) {
                                            tenantItem.floors[floor.floor] = {
                                                floorPlanUrl: floor.floorPlanUrl,
                                                floorPlanBgColor: floor.floorPlanBgColor,
                                            };
                                        }
                                    });
                                }
                                tenantsList[building.id].push(tenantItem);
                            });
                        }
                        buildingFloors[building.id] = [];
                        buildingFloors[building.id].push({
                            id: 'all',
                            value: 'All',
                        });
                        if (building.floors && Array.isArray(building.floors)) {
                            building.floors.forEach(floor => {
                                buildingFloors[building.id].push({
                                    id: floor.value,
                                    value: floor.name,
                                });
                            });
                        }
                    });
                }
                commit('setTenantsList', tenantsList);
                commit('setBuildingFloors', buildingFloors);
                commit('setBuildingList', buildingList);
                commit('updateBuildings', data.getBuildings.items);
            }
        },
        setBuildingId({ commit }, buildingId: string) {
            commit('setBuildingId', buildingId);
        },
        setTenantId({ commit }, tenantId: string) {
            commit('setTenantId', tenantId);
        },
    },
    getters: {
        getBuildings: state => state.buildings,
        getBuildingId: state => state.buildingId,
        getTenantId: state => state.tenantId,
        getListBuilding: state => state.buildingListArray,
        getTenantsByBuilding: state => {
            const buildingId = state.buildingId ? state.buildingId : '';
            const tenantsList = state.tenantsList.hasOwnProperty(buildingId)
                ? state.tenantsList[buildingId]
                : [];
            return tenantsList;
        },
        getCurrentBuilding: state => {
            const buildingId = state.buildingId ? state.buildingId : '';
            return state.buildingList.hasOwnProperty(buildingId)
                ? state.buildingList[buildingId]
                : null;
        },
        getCurrentTenant: state => {
            const buildingId = state.buildingId ? state.buildingId : '';
            if (buildingId && state.tenantsList.hasOwnProperty(buildingId)) {
                const tenants = state.tenantsList[buildingId];
                if (Array.isArray(tenants)) {
                    const tenantId = state.tenantId;
                    if (tenantId) {
                        const tenant = tenants.filter(item => {
                            return item.id.match(tenantId);
                        });
                        if (tenant[0]) {
                            return tenant[0];
                        } else return null;
                    } else return null;
                } else return null;
            } else return null;
        },
        getTenantList: state => state.tenantsList,
        getBuildingFloors: state => {
            const buildingId = state.buildingId ? state.buildingId : '';
            if (buildingId) {
                if (state.buildingFloors.hasOwnProperty(buildingId)) {
                    return state.buildingFloors[buildingId];
                } else {
                    return [];
                }
            } else return [];
        },
        getTenantFloorPlan: (state, getters) => floor => {
            const tenant = getters['getCurrentTenant'];
            if (tenant.floors && tenant.floors.hasOwnProperty(floor)) {
                return tenant.floors[floor];
            }
            return null;
        },
        getBuildingName: state => state.buildingName,
    },
};
