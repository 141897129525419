import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '@/views/Login.vue';
import store from '@/store/index';
import Dashboard from '@/views/Dashboard.vue';
import { AuthInsights } from '@/commons/authInsights';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: Dashboard,
        meta: { requiresAuth: true, viewName: 'dashboard' },
    },
    {
        path: '/login/',
        name: 'Login',
        component: Login,
        meta: { requiresAuth: false, viewName: 'login' },
    },
    {
        path: '/login/forgotpassword/',
        name: 'Forgotpassword',
        component: Login,
        meta: { requiresAuth: false, viewName: 'login' },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const isAuthenticated: boolean = await store.getters['auth/isAuthenticated'];
    // if route doesnt exist and user is not logged in redirect to login
    if (!isAuthenticated && Array.isArray(to.matched) && to.matched.length === 0) {
        store.dispatch('updateCurrentView', 'login');
        next('/login/');
        return;
    } else if (isAuthenticated && to.name === 'Login') {
        store.dispatch('updateCurrentView', 'dashboard');
        next('/');
        return;
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (isAuthenticated) {
            const buildingId = await store.getters['dashboard/getBuildingId'];
            const hasPermission = await AuthInsights.havePesrmissionForView({
                viewName: to.meta.viewName as string,
                buildingId: buildingId,
                findFirstTenant: false,
            });
            if (hasPermission || to.meta.viewName === 'dashboard') {
                store.dispatch('updateCurrentView', to.meta.viewName);
                if (to.meta.typeMenu && to.meta.typeMenu === 'main') {
                    store.dispatch('setBuildingView', to.name);
                }
                next();
                return;
            } else {
                store.dispatch('updateCurrentView', 'dashboard');
                next('/');
                return;
            }
        } else {
            store.dispatch('updateCurrentView', 'login');
            next('/login/');
            return;
        }
    } else {
        store.dispatch('updateCurrentView', to.meta.viewName);
        next();
        return;
    }
});

export default router;
