import { Module } from 'vuex';
import { Api } from '@/service/Api';
import { getOccupancyAverages, getSpaceInfo, getAreaOccupancy } from './queries/insightsQuery';
import { round } from './../common/insightsUtils';
import { useStore } from 'vuex';

interface OccupancyState {
    linechartData?: any[];
    occupancyAverages: {};
    occupancyUpdate: number;
    meetingRooms: any[];
    workstations: any[];
    focusRooms: any[];
    entityDetail: {};
    occupancyDetail: {};
    occupancyDetailUpdate: number;
    widgetValues: {};
}

export const occupancyStore: Module<OccupancyState, any> = {
    namespaced: true,
    state: () => ({
        linechartData: [],
        occupancyUpdate: 0,
        occupancyAverages: {
            avgOccupancy: 0,
            avgOccupancyMeetingRooms: 'NaN',
            avgUtilizationMeetingRooms: 'NaN',
            avgOccupancyWorkstations: 'NaN',
            avgUtilizationWorkstations: 'NaN',
            avgOccupancyFocusRooms: 'NaN',
        },
        meetingRooms: [],
        workstations: [],
        focusRooms: [],
        entityDetail: {},
        occupancyDetail: {},
        occupancyDetailUpdate: 0,
        widgetValues: {
            mostOccupiedMeetingRoom: null,
            mostUtilizedMeetingRoom: null,
            mostOccupiedFocusRoom: null,
            mostUtilizedWorkstationArea: null,
        },
    }),
    mutations: {
        updateOccupancyAverages(state, occupancyAverages) {
            state.occupancyAverages = occupancyAverages;
            //state.occupancyAveragesUpdate = occupancyAverages.occupancyAveragesUpdate;
        },
        updateLinechartData(state, chartData) {
            state.linechartData = chartData;
        },
        updateMeetingRooms(state, meetingRooms) {
            state.meetingRooms = meetingRooms;
        },
        updateWorkstations(state, workstations) {
            state.workstations = workstations;
        },
        updateFocusRooms(state, focusRooms) {
            state.focusRooms = focusRooms;
        },
        updateOccupancyUpdate(state, occupancyUpdate) {
            state.occupancyUpdate = occupancyUpdate;
        },
        updateEntityDetail(state, entityDetail) {
            state.entityDetail = entityDetail;
        },
        updateOccupancyDetail(state, occupancyDetail) {
            state.occupancyDetail = occupancyDetail;
        },
        updateOccupancyDetailUpdate(state, occupancyDetailUpdate) {
            state.occupancyDetailUpdate = occupancyDetailUpdate;
        },
        updateWidgetValues(state, widgetValues) {
            state.widgetValues = widgetValues;
        },
    },
    actions: {
        async loadData({ commit }, params: any) {
            this.dispatch('showhideLoader', true);
            const buildingId = await this.getters['dashboard/getBuildingId'];
            const tenantId = await this.getters['dashboard/getTenantId'];

            let dataType: string = 'BUILDING';

            if (params.hasOwnProperty('floor') && params.floor !== 'all') {
                dataType = 'FLOOR';
            }

            const variables: any = {
                buildingId: buildingId,
                from: params.from,
                to: params.to,
                tenantId: tenantId,
                businessHours: '915',
                spaceType: dataType,
                floor: params.floor && params.floor !== 'all' ? params.floor : '',
            };
            const occupancyResult: any = await Api.query(
                getOccupancyAverages(variables.floor),
                variables,
            );
            if (occupancyResult.data && occupancyResult.data.getOccupancyAverages) {
                const occupancyData: any = occupancyResult.data.getOccupancyAverages;
                // lineChartData
                const lineChartData =
                    params.floor && params.floor !== 'all'
                        ? occupancyData.floorValues !== null
                            ? occupancyData.floorValues
                            : []
                        : occupancyData.buildingValues !== null
                        ? occupancyData.buildingValues
                        : [];
                commit('updateLinechartData', lineChartData);

                const avgOccupancy = occupancyData.avgOccupancy
                    ? Math.floor(occupancyData.avgOccupancy)
                    : 0;
                const avgOccupancyMeetingRooms = occupancyData.avgOccupancyMeetingRooms
                    ? Math.floor(occupancyData.avgOccupancyMeetingRooms)
                    : 'NaN';
                const avgOccupancyWorkstations = occupancyData.avgOccupancyWorkstations
                    ? Math.floor(occupancyData.avgOccupancyWorkstations)
                    : 'NaN';
                const avgOccupancyFocusRooms = occupancyData.avgOccupancyFocusRooms
                    ? Math.floor(occupancyData.avgOccupancyFocusRooms)
                    : 'NaN';
                const avgUtilizationMeetingRooms = occupancyData.avgUtilizationMeetingRooms
                    ? Math.floor(occupancyData.avgUtilizationMeetingRooms)
                    : 'NaN';
                const avgUtilizationWorkstations = occupancyData.avgUtilizationWorkstations
                    ? Math.floor(occupancyData.avgUtilizationWorkstations)
                    : 'NaN';
                commit('updateOccupancyAverages', {
                    avgOccupancy: avgOccupancy,
                    avgOccupancyMeetingRooms: avgOccupancyMeetingRooms,
                    avgUtilizationMeetingRooms: avgUtilizationMeetingRooms,
                    avgOccupancyWorkstations: avgOccupancyWorkstations,
                    avgUtilizationWorkstations: avgUtilizationWorkstations,
                    avgOccupancyFocusRooms: avgOccupancyFocusRooms,
                });

                const meetingRooms: any[] = [];
                const focusRooms: any[] = [];
                const workstations: any[] = [];
                const areasData = occupancyData.areaValues;
                const sortItems = (a, b) => {
                    if (Number(a.avgOccupancy) > Number(b.avgOccupancy)) {
                        return -1;
                    } else if (Number(a.avgOccupancy) < Number(b.avgOccupancy)) {
                        return 1;
                    }
                    return 0;
                };
                areasData.sort(sortItems);
                areasData.forEach(item => {
                    if (item.spaceName === null) {
                        item.spaceName = 'Room name';
                    }
                    if (item.areaType === 'MR') {
                        if (item.avgOccupancy || item.avgUtilization) {
                            item.avgOccupancy = item.avgOccupancy
                                ? Number(item.avgOccupancy).toFixed(1)
                                : item.avgOccupancy;
                            item.avgUtilization = item.avgUtilization
                                ? Number(item.avgUtilization).toFixed(1)
                                : item.avgUtilization;
                            item.id = item.spaceId;
                            meetingRooms.push(item);
                        }
                    } else if (item.areaType === 'FR') {
                        if (item.avgOccupancy || item.avgUtilization) {
                            item.avgOccupancy = item.avgOccupancy
                                ? Number(item.avgOccupancy).toFixed(1)
                                : item.avgOccupancy;
                            item.avgUtilization = item.avgUtilization
                                ? Number(item.avgUtilization).toFixed(1)
                                : item.avgUtilization;
                            item.id = item.spaceId;
                            focusRooms.push(item);
                        }
                    } else if (item.areaType === 'WA') {
                        if (item.avgOccupancy || item.avgUtilization) {
                            item.avgOccupancy = item.avgOccupancy
                                ? Number(item.avgOccupancy).toFixed(1)
                                : item.avgOccupancy;
                            item.avgUtilization = item.avgUtilization
                                ? Number(item.avgUtilization).toFixed(1)
                                : item.avgUtilization;
                            item.id = item.spaceId;
                            workstations.push(item);
                        }
                    }
                });
                //round( mostUtilizedWorkstationArea
                const widgetValues = {
                    mostOccupiedMeetingRoom: {
                        name: occupancyData.mostOccupiedMeetingRoom
                            ? occupancyData.mostOccupiedMeetingRoom.name
                            : '',
                        value: occupancyData.mostOccupiedMeetingRoom
                            ? round(occupancyData.mostOccupiedMeetingRoom.value, 1)
                            : '',
                    },
                    mostUtilizedMeetingRoom: {
                        name: occupancyData.mostUtilizedMeetingRoom
                            ? occupancyData.mostUtilizedMeetingRoom.name
                            : '',
                        value: occupancyData.mostUtilizedMeetingRoom
                            ? round(occupancyData.mostUtilizedMeetingRoom.value, 1)
                            : '',
                    },
                    mostOccupiedFocusRoom: {
                        name: occupancyData.mostOccupiedFocusRoom
                            ? occupancyData.mostOccupiedFocusRoom.name
                            : '',
                        value: occupancyData.mostOccupiedFocusRoom
                            ? round(occupancyData.mostOccupiedFocusRoom.value, 1)
                            : '',
                    },
                    mostUtilizedWorkstationArea: {
                        name: occupancyData.mostUtilizedWorkstationArea
                            ? occupancyData.mostUtilizedWorkstationArea.name
                            : '',
                        value: occupancyData.mostUtilizedWorkstationArea
                            ? round(occupancyData.mostUtilizedWorkstationArea.value, 1)
                            : '',
                    },
                };
                commit('updateWidgetValues', widgetValues);
                commit('updateMeetingRooms', meetingRooms);
                commit('updateWorkstations', workstations);
                commit('updateFocusRooms', focusRooms);
                const occupancyUpdate = new Date().getTime();
                commit('updateOccupancyUpdate', occupancyUpdate);
            }
            this.dispatch('showhideLoader', false);
        },
        async loadSpaceData({ commit }, spaceId) {
            const store = useStore();
            const buildingId = await store.getters['dashboard/getBuildingId'];
            const tenantId = await store.getters['dashboard/getTenantId'];
            const variables: any = {
                buildingId: buildingId,
                tenantId: tenantId,
                spaceId: spaceId,
            };
            const detailResult: any = await Api.query(getSpaceInfo(), variables);
            if (detailResult.data && detailResult.data.getSpaceInfo) {
                if (
                    detailResult.data.getSpaceInfo.floor &&
                    detailResult.data.getSpaceInfo.floor.value
                ) {
                    const floorPlan = await store.getters['dashboard/getTenantFloorPlan'](
                        detailResult.data.getSpaceInfo.floor.value,
                    );
                    if (floorPlan) {
                        detailResult.data.getSpaceInfo = {
                            ...detailResult.data.getSpaceInfo,
                            ...{
                                floorPlan: {
                                    floorPlanUrl: floorPlan.floorPlanUrl,
                                    floorPlanBgColor: floorPlan.floorPlanBgColor,
                                },
                            },
                        };
                    }
                }

                commit('updateEntityDetail', detailResult.data.getSpaceInfo);
            }
        },
        async loadOccupanctDetail({ commit }, spaceId) {
            const store = useStore();
            const buildingId = await this.getters['dashboard/getBuildingId'];
            const tenantId = await this.getters['dashboard/getTenantId'];
            const curentDate = new Date();
            const endDay = new Date(
                Date.UTC(curentDate.getUTCFullYear(), curentDate.getUTCMonth() - 1, 1),
            );
            const startDay = new Date(
                Date.UTC(curentDate.getUTCFullYear(), curentDate.getUTCMonth() - 12, 1),
            );
            const variables: any = {
                buildingId: buildingId,
                tenantId: tenantId,
                spaceId: spaceId,
                businessHours: '915',
                from: startDay.getTime(),
                to: endDay.getTime(),
            };
            store.dispatch('showhideLoader', true);
            const occupancyResponse: any = await Api.query(getAreaOccupancy(), variables);
            if (occupancyResponse.data && occupancyResponse.data.getAreaOccupancy) {
                const values = occupancyResponse.data.getAreaOccupancy.values;
                const resultData = {};
                const avgOccupancy = Array();
                const avgUtilization = Array();
                const months = Array();
                let lowAvgOccupancy: any = {
                    month: '',
                    value: null,
                };
                let lowAvgUtilization: any = {
                    month: '',
                    value: null,
                };
                let highAvgOccupancy: any = {
                    month: '',
                    value: null,
                };
                let highAvgUtilization: any = {
                    month: '',
                    value: null,
                };

                if (Array.isArray(values)) {
                    for (let i = 0; i < values.length; i++) {
                        const item = values[i];
                        resultData[item.year + '_' + item.month] = item;
                    }
                    for (let i = 0; i < 12; i++) {
                        const date = new Date(
                            Date.UTC(startDay.getUTCFullYear(), startDay.getUTCMonth() + i, 1),
                        );
                        months.push(date.toLocaleString('default', { month: 'short' }));
                        if (
                            resultData.hasOwnProperty(
                                date.getUTCFullYear() + '_' + (date.getUTCMonth() + 1),
                            )
                        ) {
                            const itemData =
                                resultData[date.getUTCFullYear() + '_' + (date.getUTCMonth() + 1)];
                            const occupancyValue = itemData.avgOccupancy
                                ? Number(itemData.avgOccupancy)
                                : 0;
                            const utilizationValue = itemData.avgUtilization
                                ? Number(itemData.avgUtilization)
                                : 0;
                            avgOccupancy.push(occupancyValue);
                            avgUtilization.push(utilizationValue);
                            if (
                                !highAvgOccupancy.value ||
                                highAvgOccupancy.value < occupancyValue
                            ) {
                                highAvgOccupancy = {
                                    month: date.toLocaleString('default', { month: 'long' }),
                                    value: occupancyValue,
                                };
                            }
                            if (
                                !highAvgUtilization.value ||
                                highAvgUtilization.value < utilizationValue
                            ) {
                                highAvgUtilization = {
                                    month: date.toLocaleString('default', { month: 'long' }),
                                    value: utilizationValue,
                                };
                            }
                            if (!lowAvgOccupancy.value || lowAvgOccupancy.value >= occupancyValue) {
                                lowAvgOccupancy = {
                                    month: date.toLocaleString('default', { month: 'long' }),
                                    value: occupancyValue,
                                };
                            }
                            if (
                                !lowAvgUtilization.value ||
                                lowAvgUtilization.value >= utilizationValue
                            ) {
                                lowAvgUtilization = {
                                    month: date.toLocaleString('default', { month: 'long' }),
                                    value: utilizationValue,
                                };
                            }
                        } else {
                            avgOccupancy.push(null);
                            avgUtilization.push(null);
                        }
                    }
                }
                const occupancyDetail = {
                    spaceName: occupancyResponse.data.getAreaOccupancy.spaceName
                        ? occupancyResponse.data.getAreaOccupancy.spaceName
                        : '',
                    months: months,
                    highAvgOccupancy: highAvgOccupancy,
                    lowAvgOccupancy: lowAvgOccupancy,
                    avgOccupancy: avgOccupancy,
                    lowAvgUtilization: lowAvgUtilization,
                    highAvgUtilization: highAvgUtilization,
                    avgUtilization: avgUtilization,
                    totalMaxUtilization: occupancyResponse.data.getAreaOccupancy.maxUtilization
                        ? {
                              value: round(
                                  occupancyResponse.data.getAreaOccupancy.maxUtilization.value,
                                  1,
                              ),
                              month: occupancyResponse.data.getAreaOccupancy.maxUtilization.month,
                          }
                        : 0,
                    totalAvgUtilization: occupancyResponse.data.getAreaOccupancy.avgUtilization
                        ? round(occupancyResponse.data.getAreaOccupancy.avgUtilization, 1)
                        : 0,
                    totalAvgOccupancy: occupancyResponse.data.getAreaOccupancy.avgOccupancy
                        ? round(occupancyResponse.data.getAreaOccupancy.avgOccupancy, 1)
                        : 0,
                };

                commit('updateOccupancyDetail', occupancyDetail);
                commit('updateOccupancyDetailUpdate', new Date().getTime());
            }
            store.dispatch('showhideLoader', false);
        },
    },
    getters: {
        getLinechartData: state => state.linechartData,
        getOccupancyAverages: state => state.occupancyAverages,
        getOccupancyUpdate: state => state.occupancyUpdate,
        getMeetingRooms: state => state.meetingRooms,
        getWorkstations: state => state.workstations,
        getFocusRooms: state => state.focusRooms,
        getEntityDetail: state => state.entityDetail,
        getOccupancyDetail: state => state.occupancyDetail,
        getOccupancyDetailUpdate: state => state.occupancyDetailUpdate,
        getSpaceData: state => async spaceId => {
            const store = useStore();
            const buildingId = await store.getters['dashboard/getBuildingId'];
            const tenantId = await store.getters['dashboard/getTenantId'];
            const variables: any = {
                buildingId: buildingId,
                tenantId: tenantId,
                spaceId: spaceId,
            };
            const detailResult: any = await Api.query(getSpaceInfo(), variables);
            if (detailResult.data) {
                return detailResult.data.getSpaceInfo;
            } else return {};
        },
        getWidgetValues: state => state.widgetValues,
    },
};
