import Occupancy from '@/views/modules/insights/views/Occupancy.vue';
import Enviro from '@/views/modules/insights/views/Enviro.vue';
import EntityDetail from '@/views/modules/insights/views/EntityDetail.vue';
import OcupencyDetail from '@/views/modules/insights/views/OccupencyDetail.vue';
import EnviroDetail from '@/views/modules/insights/views/EnviroDetail.vue';
import DeskBooking from '@/views/modules/deskbookig/views/DeskBooking.vue';
import UserPermission from '@/views/modules/admin/views/UserPermission.vue';
import UserManagement from '@/views/modules/admin/views/UserManagement.vue';
import Energy from '@/views/modules/insights/views/Energy.vue';

const modules = [
    {
        name: 'WorkspaceInsights',
        selectionPage: {
            tenant: [
                {
                    name: 'Occupancy',
                    path: '/occupancy/',
                    component: Occupancy,
                    mainMenu: true,
                    statisticType: 'ST001',
                    viewName: 'occupancy',
                    permissionGroups: ['MonthlyOccupancy'],
                    typeMenu: 'main',
                    icon: 'icon-insights hb-icon-users',
                },
                {
                    name: 'Environment',
                    path: '/environment/',
                    component: Enviro,
                    mainMenu: true,
                    statisticType: 'ST002',
                    viewName: 'enviro',
                    permissionGroups: ['MonthlyEnviro'],
                    typeMenu: 'main',
                    icon: 'icon-insights hb-icon-comfort',
                },
                {
                    name: 'Metadata',
                    path: '/space-detail-metadata/:spaceId',
                    component: EntityDetail,
                    mainMenu: false,
                    permissionGroups: ['MonthlyOccupancy', 'MonthlyEnviro'],
                    typeMenu: 'spaceDetail',
                    viewName: 'metadata',
                    icon: 'icon-insights hb-icon-comfort',
                },
                {
                    name: 'Detail occupancy',
                    path: '/occupancy-detail/:spaceId',
                    component: OcupencyDetail,
                    mainMenu: false,
                    permissionGroups: ['MonthlyOccupancy'],
                    typeMenu: 'spaceDetail',
                    viewName: 'occupancy-detail',
                    icon: 'icon-insights hb-icon-comfort',
                },
                {
                    name: 'Detail environment',
                    path: '/enviro-detail/:spaceId',
                    component: EnviroDetail,
                    mainMenu: false,
                    permissionGroups: ['MonthlyEnviro'],
                    typeMenu: 'spaceDetail',
                    viewName: 'enviro-detail',
                    icon: 'icon-insights hb-icon-comfort',
                },
                {
                    name: 'Desk Booking',
                    path: '/deskbooking/',
                    component: DeskBooking,
                    mainMenu: true,
                    permissionGroups: ['DeskBooking'],
                    typeMenu: 'main',
                    statisticType: 'ST003',
                    viewName: 'deskBooking',
                    icon: 'icon-insights hb-icon-desk-booking',
                },
                {
                    name: 'Energy',
                    path: '/energy/',
                    component: Energy,
                    mainMenu: true,
                    statisticType: 'ST004',
                    viewName: 'energy',
                    permissionGroups: ['MonthlyEnergy'],
                    typeMenu: 'main',
                    icon: 'icon-insights hb-energy-consumption',
                },
            ],
            building: [],
            independent: [
                {
                    name: 'User Management',
                    path: '/usermanagement/',
                    component: UserManagement,
                    mainMenu: true,
                    permissionGroups: ['UserManagement'],
                    typeMenu: 'adminMenu',
                    viewName: 'userManagement',
                    icon: 'icon-insights hb-icon-users',
                },
                {
                    name: 'User Permissions',
                    path: '/userpermission/',
                    component: UserPermission,
                    mainMenu: true,
                    permissionGroups: ['UserManagement'],
                    typeMenu: 'adminMenu',
                    viewName: 'userPermission',
                    icon: 'icon-insights hb-icon-desk-booking',
                },
            ],
        },
    },
];
export default modules;
