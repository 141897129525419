import { gql } from 'graphql-tag';

export const getSpaceOccupancyMonth = (floor: string = '', items: string = 'all') => {
    let showItems =
        'areaType avgOccupancy avgUtilization buildingId businessHours capacity month spaceId spaceName spaceType tenantId timestamp year';
    if (items !== 'all') {
        showItems = items;
    }
    return gql(`query getSpaceOccupancyMonth ($buildingId: String!, $from: AWSTimestamp!, $to: AWSTimestamp!, $businessHours: String!, $spaceType: String!, $tenantId: String!${
        floor ? ', $floor: String!' : ''
    }) {
        getSpaceOccupancyMonth(buildingId: $buildingId, from: $from, businessHours: $businessHours, spaceType: $spaceType, tenantId: $tenantId, to: $to${
            floor ? ', floor: $floor' : ''
        }) {
          nextToken
          items {
            ${' ' + showItems + ' '}
            ${floor ? 'floor' : ''}
          }
        }
      }`);
};

export const getEnviroAverages = (floor: string = '') => {
    return gql(
        `query getEnviroAverages ($buildingId: String!, $from: AWSTimestamp!, $to: AWSTimestamp!, $spaceType: String!,  $tenantId: String!, $businessHours: String!${
            floor ? ', $floor: String!' : ''
        }) { getEnviroAverages(buildingId:$buildingId, from: $from, to: $to, spaceType: $spaceType, tenantId: $tenantId, businessHours: $businessHours${
            floor ? ', floor: $floor' : ''
        }) {
            ${floor ? 'floor floorValues { eci month year }' : ''}
            avgCo2
            avgEci
            avgHumidity
            areaValues {
                co2Good
                co2Poor
                co2Excellent
                eci
                floor
                humidityAboveRange
                humidityBelowRange
                humidityInRange
                spaceName
                spaceId
            }
            ${floor ? '' : 'buildingValues { eci month year }'}
        } 
    }`,
    );
};

export const getEnergyAverages = () => {
    return gql(
        `query getEnergyAverages ($buildingId: String!, $tenantId: String!, $from: AWSTimestamp!, $to: AWSTimestamp!) {
            getEnergyAverages(buildingId:$buildingId, tenantId: $tenantId, from: $from, to: $to) {
            buildingValues {
                waterConsumption
                waterConsumptionM2
                electricityConsumption
                electricityConsumptionM2
                heatingConsumption
                heatingConsumptionM2
                coolingConsumption
                coolingConsumptionM2
            }
        } 
    }`,
    );
};

export const getOccupancyAverages = (floor: string = '') => {
    return gql(
        `query getOccupancyAverages ($buildingId: String!, $from: AWSTimestamp!, $to: AWSTimestamp!, $spaceType: String!,  $tenantId: String!, $businessHours: String!${
            floor ? ', $floor: String!' : ''
        }) { getOccupancyAverages(buildingId:$buildingId, from: $from, to: $to, spaceType: $spaceType, tenantId: $tenantId, businessHours: $businessHours${
            floor ? ', floor: $floor' : ''
        }) {
            ${floor ? 'floor floorValues { avgOccupancy avgUtilization month year }' : ''}
            avgOccupancy
            avgOccupancyFocusRooms
            avgOccupancyMeetingRooms
            avgOccupancyWorkstations
            avgUtilization
            avgUtilizationWorkstations
            avgUtilizationMeetingRooms
            areaValues {
                avgOccupancy
                avgUtilization
                spaceName
                areaType
                spaceId
                capacity
            }
            mostOccupiedFocusRoom {
                month
                name
                value
              }
              mostOccupiedMeetingRoom {
                month
                name
                value
              }
              mostUtilizedMeetingRoom {
                month
                name
                value
              }
              mostUtilizedWorkstationArea {
                month
                name
                value
              }
            ${floor ? '' : 'buildingValues { avgOccupancy avgUtilization month year  }'}
        } 
    }`,
    );
};

export const getSpaceInfo = () => {
    return gql(`query getSpaceInfo ($buildingId: String!, $spaceId: String!, $tenantId: String!) {
            getSpaceInfo ( buildingId: $buildingId, spaceId: $spaceId, tenantId: $tenantId ) {
                areaType
                bookable
                docking
                capacity
                screen
                size
                spaceId
                spaceName
                videoconferencing
                floor {
                    name
                    value
                }
                imageUrl
                floorPlanId
            }
        
    }`);
};
export const getAreaOccupancy = () => {
    return gql(`query getAreaOccupancy($buildingId: String!, $spaceId: String!, $tenantId: String!, $businessHours: String!, $from: AWSTimestamp!, $to: AWSTimestamp!) {
        getAreaOccupancy( buildingId: $buildingId, spaceId: $spaceId, tenantId: $tenantId, businessHours: $businessHours, from: $from, to: $to ) {
            spaceName
            maxUtilization {
                month
                value
            }
            avgUtilization
            avgOccupancy
            values {
                avgOccupancy
                avgUtilization
                month
                spaceName
                year
            }
        }
    }`);
};
export const getAreaEnviro = () => {
    return gql(`query getAreaEnviro($buildingId: String!, $spaceId: String!, $tenantId: String!, $businessHours: String!, $from: AWSTimestamp!, $to: AWSTimestamp!) {
        getAreaEnviro( buildingId: $buildingId, spaceId: $spaceId, tenantId: $tenantId, businessHours: $businessHours, from: $from, to: $to ) {
            spaceName
            values {
                humidityAboveRange
                humidityBelowRange
                humidityInRange
                floor
                eci
                co2Poor
                co2Good
                co2Excellent
                spaceName
                month
                year
            }
            bestCo2 {
                month
                name
                value
            }
            bestHumidity {
                month
                name
                value
            }
            worstCo2 {
                month
                name
                value
            }
            worstHumidity {
                month
                name
                value
            }
        }
    }`);
};
