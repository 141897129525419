import { gql } from 'graphql-tag';

export const getDeskBookings = (floor: string = '') => {
    return gql(`query getDeskBookings($buildingId: String!, $tenantId: String!, $month: Int!, $year: Int!) {
        getDeskBookings( buildingId: $buildingId, tenantId: $tenantId, month: $month, year: $year ) {
            amBookingsPercentage
            dayBookingsPercentage
            pmBookingsPercentage
            canceledBookings
            mostBookings {
                date
                name
                value
            }
            values {
                afterNoonBookings
                beforeNoonBookings
                dayBookings
                day
            }
        }
    }`);
};

export const getDeskBookingsByDesk = (floor: string = '') => {
    return gql(`query getDeskBookingsByDesk($buildingId: String!, $tenantId: String!, $month: Int!, $year: Int!) {
        getDeskBookingsByDesk( buildingId: $buildingId, tenantId: $tenantId, month: $month, year: $year ) {
            leastBookedDesk {
                name
                value
            }
            mostBookedDesk {
                name
                value
            }
            values {
                deskName
                month
                totalBookings
                totalBookingsPercentage
                totalBookingsUsage
                totalCount
                deskId
                dayBookingsUsage
                dayBookingsPercentage
                dayBookings
                beforeNoonBookingsUsage
                beforeNoonBookingsPercentage
                beforeNoonBookings
                afterNoonBookingsUsage
                afterNoonBookingsPercentage
                afterNoonBookings
            }
        }
    }`);
};
