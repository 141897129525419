import { Chart } from 'chart.js';
import { getDefaultFontFamily } from '../utils/helper';

export interface SpeedOptions {
  barColor: string;
  barBackgroundColor: string;
  middleTextColor: string;
  startEndTextColor: string;
  unitText: string;
  showMiddleText: boolean;
  showStartEndText: boolean;
  fontFamily: string;
}

/**
 *
 * @param speedOptions
 * @returns Object plugin
 * @description plugin for a Doughnut chart
 */
export const speedChart = (speedOptions: SpeedOptions): {} => {
  return {
    id: 'speed-chart',
    afterDatasetsDraw: function (chart: Chart) {
      const first = Number(chart.config.data.datasets[0].data[0]);
      const second = Number(chart.config.data.datasets[0].data[1]);
      const max = first + second;
      const {
        ctx,
        canvas,
        chartArea: { top, width, height, bottom },
      } = chart;
      // radius on one side
      const arc: any = chart.getDatasetMeta(0).data[0];
      const thickness = arc.outerRadius - arc.innerRadius;
      const startAngle = Math.PI / 2 - arc.startAngle;
      const endAngle = Math.PI / 2 - arc.endAngle;
      ctx.save();
      if (chart.config.data.datasets[0].data[0] != 0) {
        ctx.translate(arc.x, arc.y);
        ctx.fillStyle = arc.options.backgroundColor;
        ctx.beginPath();
        const radius =
          arc.outerRadius - (arc.outerRadius - arc.innerRadius) / 2;
        ctx.arc(
          radius * Math.sin(endAngle),
          radius * Math.cos(endAngle),
          thickness / 2,
          0,
          2 * Math.PI
        );
        ctx.fillStyle = arc.options.backgroundColor;
        ctx.closePath();
        ctx.fill();
        ctx.restore();
      }
      // end radius on one side

      const cw = canvas.offsetWidth;
      const ch = canvas.offsetHeight;
      const cx = cw / 2;
      const cy = ch - ch / 4;
      const arrowCenter = cy - thickness * 1.5;
      const b = thickness * 2 + thickness / 2;
      const a = arc.x - thickness * 3;
      const c = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
      const aSinus = a / c;
      const difAngle = Math.PI - Math.PI * aSinus;
      const angle = startAngle - endAngle;
      const radianAngle =
        Math.PI - difAngle + (Math.PI + 2 * difAngle) * (angle / Math.PI);
      ctx.translate(cx, arrowCenter);
      ctx.rotate(radianAngle);

      // Arrow
      const arrowWidth = arc.x - thickness * 3;
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(0, -thickness / 2);
      ctx.lineTo(arrowWidth, -thickness / 4);
      ctx.lineTo(arrowWidth, thickness / 4);
      ctx.lineTo(0, thickness / 2);
      ctx.lineTo(0, 0);
      ctx.fillStyle = arc.options.backgroundColor;
      ctx.fill();
      ctx.rotate(-radianAngle);
      ctx.translate(-cx, -arrowCenter);

      // Circle
      ctx.beginPath();
      ctx.arc(cx, arrowCenter, thickness, 0, Math.PI * 2);
      ctx.fillStyle = 'white';
      ctx.shadowBlur = 4;
      ctx.shadowOffsetY = 3;
      ctx.shadowColor = 'rgba(180, 180, 180, 0.278846)';
      ctx.fill();

      ctx.beginPath();
      ctx.arc(cx, arrowCenter, thickness / 2, 0, Math.PI * 2);
      ctx.fillStyle = arc.options.backgroundColor;
      ctx.fill();

      // center text
      if (speedOptions.showMiddleText) {
        ctx.font = 2 * thickness + 'px ' + speedOptions.fontFamily;
        ctx.textAlign = 'center';
        ctx.fillStyle = speedOptions.middleTextColor;
        ctx.fillText(first + speedOptions.unitText, cx, cy + 2 * thickness);
        ctx.restore();
      }

      if (speedOptions.showStartEndText) {
        // left text
        ctx.font = thickness + 'px ' + speedOptions.fontFamily;
        ctx.textAlign = 'left';
        ctx.fillStyle = speedOptions.startEndTextColor;
        ctx.fillText(
          '0' + speedOptions.unitText,
          cx - arc.x,
          cy + 1.5 * thickness
        );
        ctx.restore();

        // right text
        ctx.font = thickness + 'px ' + speedOptions.fontFamily;
        ctx.textAlign = 'right';
        ctx.fillStyle = speedOptions.startEndTextColor;
        ctx.fillText(
          max + speedOptions.unitText,
          cx + arc.x,
          cy + 1.5 * thickness
        );
        ctx.restore();
      }
    },
  };
};

interface HightlightArea {
  from: number;
  to: number;
}

export interface InsightLineData {
  hightlightArea: HightlightArea;
  labels: string[];
  values: (undefined | number)[];
}

interface InsightsLineTicks {
  color: string;
  stepSize: number;
}

export interface InsightLineOptions {
  max: number;
  hightlightcolor: string;
  borderColor: string;
  pointBackgroundColor: string;
  ticks: InsightsLineTicks;
  unit: string;
}

export interface StackedBarChartOptions {
  max?: number;
  borderColor: string | Array<string>;
  backgroundColor: string | Array<string>;
  ticks?: any;
  barThickness: number | string;
  scrolling: boolean;
  maxItems: number;
  legendDisplay?: boolean;
  unit: string;
}

/**
 *
 * @returns Object plugin
 * @description plugin for line chart
 */
export const highlightLineChart = (
  chartOptions: InsightLineOptions,
  chartData: InsightLineData | undefined
): {} => {
  return {
    id: 'highlight-area',
    afterDatasetDraw(chart: Chart, args: any, pluginOptions: any) {
      const {
        ctx,
        canvas,
        chartArea: { top, width, height, bottom },
      } = chart;

      // console.log(chart.options.animation);
      if (chartData !== undefined) {
        if (chartData.hightlightArea !== undefined) {
          if (
            chartData.hightlightArea.from !== undefined &&
            chartData.hightlightArea.to !== undefined
          ) {
            const from = chartData.hightlightArea.from;
            const to = chartData.hightlightArea.to;
            if (from <= to && chartData.labels) {
              if (to - from > 0) {
                ctx.beginPath();
                ctx.strokeStyle = chartOptions.hightlightcolor;
                ctx.moveTo(chart.getDatasetMeta(0).data[from].x, bottom);
                ctx.lineTo(
                  chart.getDatasetMeta(0).data[from].x,
                  chart.getDatasetMeta(0).data[from].y
                );
                for (let i = from + 1; i <= to; i++) {
                  ctx.lineTo(
                    chart.getDatasetMeta(0).data[i].x,
                    chart.getDatasetMeta(0).data[i].y
                  );
                }
                ctx.lineTo(chart.getDatasetMeta(0).data[to].x, bottom);
                ctx.lineTo(chart.getDatasetMeta(0).data[from].x, bottom);
                ctx.fillStyle = chartOptions.hightlightcolor;
                ctx.globalAlpha = 0.3;
                ctx.fill();
                ctx.restore();
              }
              ctx.globalAlpha = 1;
              for (let i = from; i <= to; i++) {
                if (chart.getDatasetMeta(0).data[i]) {
                  ctx.beginPath();
                  ctx.moveTo(chart.getDatasetMeta(0).data[i].x, bottom);
                  ctx.lineWidth = width > 300 ? 4 : 3;
                  ctx.strokeStyle = chartOptions.hightlightcolor;
                  ctx.lineTo(
                    chart.getDatasetMeta(0).data[i].x,
                    chart.getDatasetMeta(0).data[i].y
                  );
                  ctx.stroke();

                  ctx.beginPath();
                  ctx.arc(
                    chart.getDatasetMeta(0).data[i].x,
                    chart.getDatasetMeta(0).data[i].y,
                    width > 300 ? 4 : 3,
                    0,
                    2 * Math.PI
                  );
                  ctx.fillStyle = chartOptions.hightlightcolor;
                  ctx.fill();
                  ctx.stroke();
                }
              }
            }
          }
        }
      }
    },
  };
};
