import { createStore } from 'vuex';
import { authStore } from '@/store/modules/auth';
import { loadApp } from '@/store/modules/loadApp';
//import { selectionStore } from '@/store/modules/selectionStore';
import { dashboardStore } from '@/store/modules/dashboardStore';

interface MainMenu {
    name: string;
    path: string;
}

interface MainStoreState {
    currentMainMenu: MainMenu[];
    mainMenu: MainMenu[];
    menuHierarchy: {};
    showLoader: boolean;
    openLoader: number;
    hierarchy: Object;
    currentView: string;
    buildingView: string;
    menus: {};
}
const initialState = (): MainStoreState => {
    const buildingView = sessionStorage.getItem('buildingView');
    return {
        currentMainMenu: [],
        mainMenu: [],
        showLoader: false,
        openLoader: 0,
        hierarchy: Object,
        menuHierarchy: {},
        currentView: '',
        menus: {},
        buildingView: buildingView ? buildingView : '',
    };
};
export default createStore<MainStoreState>({
    state: initialState(),
    mutations: {
        loadHome(state) {
            state.currentMainMenu = new Array<MainMenu>();
        },
        loadMainMenu(state, mainMenu) {
            state.mainMenu = mainMenu;
        },
        loadMenus(state, menus) {
            state.menus = menus;
        },
        showhideLoader(state, value: boolean) {
            if (value && state.openLoader === 0) {
                state.openLoader = ++state.openLoader;
                state.showLoader = value;
            } else if (value && state.openLoader > 0) {
                state.openLoader = ++state.openLoader;
            } else if (!value && state.openLoader > 1) {
                state.openLoader = --state.openLoader;
            } else if (!value && state.openLoader === 1) {
                state.openLoader = --state.openLoader;
                state.showLoader = value;
            }
        },
        setHierarchy(state, hierarchy: Object) {
            state.hierarchy = hierarchy;
        },
        setMenuHierarchy(state, menuHierarchy) {
            state.menuHierarchy = menuHierarchy;
        },
        updateCurrentView(state, currentView) {
            state.currentView = currentView;
        },
        setBuildingView(state, buildingView) {
            sessionStorage.setItem('buildingView', buildingView);
            state.buildingView = buildingView;
        },
    },
    actions: {
        loadHome({ commit }) {
            commit('loadHome');
        },
        addToMainMennu({ commit }) {
            commit('addToMainMennu');
        },
        loadMainMenu({ commit }, mainMenu) {
            if (Array.isArray(mainMenu)) {
                const menuHierarchy = Object();
                mainMenu.forEach(menuItem => {
                    menuHierarchy[menuItem.viewName] = menuItem;
                });
                commit('setMenuHierarchy', menuHierarchy);
            }
            commit('loadMainMenu', mainMenu);
        },
        loadMenus({ commit }, menus) {
            commit('loadMenus', menus);
        },
        showhideLoader({ commit }, value: boolean) {
            commit('showhideLoader', value);
        },
        setHierarchy({ commit }, hierarchy: Object) {
            commit('setHierarchy', hierarchy);
        },
        updateCurrentView({ commit }, currentView) {
            commit('updateCurrentView', currentView);
        },
        setBuildingView({ commit }, buildingView) {
            commit('setBuildingView', buildingView);
        },
    },
    getters: {
        getCurrentMenu: state => state.currentMainMenu,
        showLoader: state => state.showLoader,
        getHierarchy: state => state.hierarchy,
        getMenuHierarchy: state => state.menuHierarchy,
        getCurrentView: state => state.currentView,
        getMainMenu: state => state.mainMenu,
        getMenus: state => state.menus,
        getBuildingView: state => state.buildingView,
    },
    modules: {
        auth: authStore,
        loadApp: loadApp,
        //selection: selectionStore,
        dashboard: dashboardStore,
    },
});
