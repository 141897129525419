import { Module } from 'vuex';
import { Api } from '@/service/Api';
import {
    getModules,
    userList,
    updateUserPermissions,
    deleteUserMutation,
    createUserMutation,
} from '@/views/modules/admin/store/queries/adminQueries';
import { toRaw } from 'vue';
interface UsermanState {
    modules: any[];
    userPermission: {};
    userManagementData: {};
    userList: any[];
}

export const usermanStore: Module<UsermanState, any> = {
    namespaced: true,
    state: () => ({
        modules: [],
        userPermission: {},
        userManagementData: {
            modules: [],
        },
        userList: [],
    }),
    mutations: {
        updateUserPermission(state, userPermission) {
            state.userPermission = userPermission;
        },
        updateUserManagementData(state, userManagementData) {
            state.userManagementData = userManagementData;
        },
        updateUserList(state, userList) {
            state.userList = userList;
        },
    },
    actions: {
        async loadUserPermissiontData({ commit }) {
            try {
                this.dispatch('showhideLoader', true);
                const modulesResult: any = await Api.query(getModules());
                const userListResult: any = await Api.query(userList(), {}, false);
                const getListBuilding = toRaw(await this.getters['dashboard/getListBuilding']);
                const getTenantList = toRaw(await this.getters['dashboard/getTenantList']);
                const modules = {};
                if (modulesResult && modulesResult.data && modulesResult.data.getModules) {
                    const modulesItems = modulesResult.data.getModules.items;
                    if (modulesItems && Array.isArray(modulesItems)) {
                        modulesItems.forEach(moduleItem => {
                            const building = getListBuilding.filter(item => {
                                if (item.id === moduleItem.buildingId) return true;
                                return false;
                            });
                            const tenant = getTenantList[moduleItem.buildingId]
                                ? getTenantList[moduleItem.buildingId].filter(item => {
                                      if (item.id === moduleItem.tenantId) return true;
                                      else return false;
                                  })
                                : [];
                            if (!modules.hasOwnProperty(moduleItem.module)) {
                                modules[moduleItem.module] = {
                                    name: moduleItem.module,
                                    id: moduleItem.module,
                                };
                            }
                            if (!modules[moduleItem.module].hasOwnProperty(moduleItem.buildingId)) {
                                modules[moduleItem.module][moduleItem.buildingId] = {
                                    id: moduleItem.buildingId,
                                    name: building.length > 0 ? building[0].name : 'No Name',
                                };
                            }
                            if (
                                !modules[moduleItem.module][moduleItem.buildingId].hasOwnProperty(
                                    moduleItem.tenantId,
                                )
                            ) {
                                modules[moduleItem.module][moduleItem.buildingId][
                                    moduleItem.tenantId
                                ] = {
                                    id: moduleItem.tenantId,
                                    name: tenant.length > 0 ? tenant[0].name : '',
                                    value: false,
                                };
                            }
                            const item = {
                                module: 'Module Name',
                                buildings: [
                                    {
                                        buildingId: 'B0004',
                                        name: 'Building name',
                                        tenants: [{ tenantId: 'T0002', name: 'Tenant Name' }],
                                    },
                                ],
                            };
                        });
                    }
                }
                // create array of modules, building and tenant
                const moduleNames = Object.keys(modules);
                const moduleArray = Array();
                moduleNames.forEach((moduleKey: any) => {
                    const moduleItem = modules[moduleKey];
                    const moduleBuildingNames = Object.keys(moduleItem);
                    const buildingArray = Array();
                    moduleBuildingNames.forEach((buildingKey: any) => {
                        const buildingItem = moduleItem[buildingKey];
                        if (buildingItem.id !== undefined) {
                            const moduleTenants = Object.keys(buildingItem);
                            const tenantArray = Array();
                            moduleTenants.forEach((tenantKey: any) => {
                                const tenantItem = buildingItem[tenantKey];
                                if (tenantItem.id !== undefined) {
                                    const tenantItemArray = {
                                        id: tenantItem.id,
                                        name: tenantItem.name,
                                    };
                                    tenantArray.push(tenantItemArray);
                                }
                            });

                            const buildingItemArray = {
                                id: buildingItem.id,
                                name: buildingItem.name,
                                tenants: tenantArray,
                            };
                            buildingArray.push(buildingItemArray);
                        }
                    });
                    const moduleItemArray = {
                        name: moduleItem.id,
                        id: moduleItem.name,
                        buildings: buildingArray,
                    };
                    moduleArray.push(moduleItemArray);
                });
                const userPermission = {};
                const userListData = Array();
                if (userListResult && userListResult.data && userListResult.data.userList) {
                    const itemsPermission = userListResult.data.userList.items;
                    if (itemsPermission && Array.isArray(itemsPermission)) {
                        itemsPermission.forEach(permissionItem => {
                            if (!userPermission.hasOwnProperty(permissionItem.email)) {
                                userPermission[permissionItem.email] = {};
                                userListData.push({
                                    id: permissionItem.email,
                                    value: permissionItem.email,
                                });
                            }
                            const permissionModules = Object.keys(permissionItem.permissions);
                            if (permissionModules && Array.isArray(permissionModules)) {
                                permissionModules.forEach(permissionModule => {
                                    if (permissionModule !== '__typename') {
                                        userPermission[permissionItem.email][permissionModule] =
                                            permissionItem.permissions[permissionModule];
                                    }
                                });
                            }
                        });
                    }
                }
                const userManagementData = {
                    modules: moduleArray,
                    userPermission: userPermission,
                    userList: userListData,
                    permissionData: modules,
                };
                commit('updateUserManagementData', userManagementData);
                this.dispatch('showhideLoader', false);
            } catch (e) {
                this.dispatch('showhideLoader', false);
            }
        },
        async savePermissions({}, permission) {
            try {
                this.dispatch('showhideLoader', true);

                let modules = [...Object.keys(permission)];
                modules = modules.filter(item => {
                    if (typeof permission[item] === 'object') {
                        return true;
                    } else return false;
                });

                const occupancyResult: any = await Api.mutation(
                    updateUserPermissions(modules),
                    permission,
                );

                this.dispatch('showhideLoader', false);
            } catch (e) {
                this.dispatch('showhideLoader', false);
            }
        },
        async loadUserManagementData({ commit }) {
            try {
                this.dispatch('showhideLoader', true);
                const userListData = Array<any>();
                const userListResult: any = await Api.query(userList(), {}, false);
                if (userListResult && userListResult.data && userListResult.data.userList) {
                    const userItems = userListResult.data.userList.items;
                    if (Array.isArray(userItems)) {
                        userItems.forEach(item => {
                            userListData.push({
                                id: item.email,
                                value: item.email,
                            });
                        });
                        commit('updateUserList', userListData);
                    }
                }
                this.dispatch('showhideLoader', false);
            } catch (e) {
                this.dispatch('showhideLoader', false);
            }
        },
        async deleteUser({ dispatch }, userId) {
            try {
                console.log(userId);
                this.dispatch('showhideLoader', true);
                const deleteResult: any = await Api.mutation(deleteUserMutation(), {
                    email: userId,
                });
                await dispatch('loadUserManagementData');
                this.dispatch('showhideLoader', false);
            } catch (e) {
                this.dispatch('showhideLoader', false);
            }
        },
        async createUser({ dispatch }, userEmail) {
            try {
                this.dispatch('showhideLoader', true);
                await Api.mutation(createUserMutation(), {
                    email: userEmail,
                });
                await dispatch('loadUserManagementData');
                this.dispatch('showhideLoader', false);
            } catch (e) {
                this.dispatch('showhideLoader', false);
            }
        },
    },
    getters: {
        getUserManagementData: state => state.userManagementData,
        getUserPermission: state => state.userPermission,
        getUserList: state => state.userList,
    },
};
