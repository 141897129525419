export const deepToObject = (obj: any) => {
    const stringJson = JSON.stringify(obj);
    return stringJson;
};

export function remToPixels(remSize: number): number {
    return remSize * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const round = (num, decimalPlaces = 0): number => {
    // @ts-ignore
    num = Math.round(num + 'e' + decimalPlaces);
    return Number(num + 'e' + -decimalPlaces);
};

export function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'long' });
}
