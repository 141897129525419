<template>
    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="top-bar">
                        <a href="#" @click.stop.prevent="$emit('close')">
                            <i class="icon-insights hb-icon-close" />
                        </a>
                    </div>
                    <div class="modal-header">
                        <slot name="header"></slot>
                    </div>

                    <div class="modal-body">
                        <slot name="body"></slot>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
export default {
    components: {},
    props: {
        show: Boolean,
    },
    emits: ['close'],
};
</script>
<style lang="scss">
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    display: block;
    //flex-direction: column;
    width: 80vw;
    max-height: 80vmax;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    @include fromScreen(md) {
        width: 60vw;
    }
    .top-bar {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .modal-header {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        background: transparent;
        width: 100%;
        .title {
            font-size: 1.38rem;
            font-weight: 400;
            justify-self: flex-start;
        }
        .nav {
            justify-self: flex-end;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 1rem;
            .nav-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                > span {
                    padding-left: 0.38rem;
                    padding-right: 0.6rem;
                    color: $primary-anthracite;
                    opacity: 0.35;
                }
                > i {
                    background-color: $primary-anthracite;
                    width: 0.63rem;
                    height: 0.63rem;
                    opacity: 0.35;
                }
            }
        }
    }
    .vertical-divider {
        width: 100%;
        height: 0.5rem;
    }
    .modal-body {
        margin: 20px 0;
    }
}

.modal-enter-from {
    opacity: 0;
}

.modal-leave-to {
    opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
