import { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { DocumentNode } from '@apollo/client';
/**
 * https://github.com/MoreAppka/hbr-pwa-request/blob/develop/src/request.ts
 * https://docs.aws.amazon.com/appsync/latest/devguide/quickstart-write-queries.html
 */

export interface OccuppancySpaceMonthVar {
    tenantId: string;
    buildingId: string;
    from: Number;
    to: Number;
    spaceType: string;
}

export class ApiClass {
    private client: AWSAppSyncClient<any> | null;

    constructor() {
        this.client = this.createClient();
    }

    private createClient(): AWSAppSyncClient<any> {
        return new AWSAppSyncClient({
            url: process.env.VUE_APP_GRAPHQL_ENDPOINT || '',
            region: process.env.VUE_APP_REGION || '',
            auth: {
                type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
                jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
            },
            disableOffline: true,
        });
    }

    public async mutation(mutation: DocumentNode, variables: any = {}) {
        try {
            const options: any = {
                mutation: mutation,
                variables: variables,
            };
            return await this.client!.mutate(options);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
    public async query(query: DocumentNode, variables: any = {}, cache = false) {
        try {
            const options: any = {
                query: query,
                variables: variables,
            };
            if (!cache) {
                options['fetchPolicy'] = 'network-only';
            }
            console.log('query options', options);
            return this.client!.query(options);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
export const Api = new ApiClass();
