import { Module } from 'vuex';
import { Api } from '@/service/Api';
import { getEnergyAverages } from './queries/insightsQuery';
import { round } from '@/commons/utils';

const EXPIRATION_PERIOD = 1000 * 60 * 5;

interface EnergyState {
    consumption: {
        [key: string]: {
            waterConsumption: number[];
            waterConsumptionM2: number[];
            electricityConsumption: number[];
            electricityConsumptionM2: number[];
            heatingConsumption: number[];
            heatingConsumptionM2: number[];
            coolingConsumption: number[];
            coolingConsumptionM2: number[];
        };
    };
}

const defaultStore = {
    consumption: {},
};

export const energyStore: Module<EnergyState, any> = {
    namespaced: true,
    state: () => ({ ...defaultStore }),
    mutations: {
        updateConsumption(state, { key, ...rest }) {
            state.consumption[key] = rest;
        },
    },
    actions: {
        async loadData({ commit, getters }, { from, to }: { from: number; to: number }) {
            if (!from || !to) return;

            const [buildingId, tenantId] = await Promise.all([
                this.getters['dashboard/getBuildingId'],
                this.getters['dashboard/getTenantId'],
            ]);
            const key = `${buildingId}#${tenantId}#${from}-${to}`;
            const cachedData = getters.consumptionByKey(key);
            const now = new Date().getTime();

            if (cachedData && cachedData.expiration > now) return;

            this.dispatch('showhideLoader', true);

            const energyResult: any = await Api.query(getEnergyAverages(), {
                buildingId,
                tenantId,
                from,
                to,
            });

            if (
                energyResult.data &&
                energyResult.data.getEnergyAverages &&
                energyResult.data.getEnergyAverages.buildingValues
            ) {
                const consumption = energyResult.data.getEnergyAverages.buildingValues.reduce(
                    (acc: any, record) => {
                        // Water
                        acc.values.waterConsumption.push(
                            isNaN(record.waterConsumption)
                                ? null
                                : round(record.waterConsumption, 1),
                        );
                        acc.values.waterConsumptionM2.push(
                            isNaN(record.waterConsumptionM2)
                                ? null
                                : round(record.waterConsumptionM2, 1),
                        );
                        // Electricity
                        acc.values.electricityConsumption.push(
                            isNaN(record.electricityConsumption)
                                ? null
                                : round(record.electricityConsumption, 1),
                        );
                        acc.values.electricityConsumptionM2.push(
                            isNaN(record.electricityConsumptionM2)
                                ? null
                                : round(record.electricityConsumptionM2, 1),
                        );
                        // Heating
                        acc.values.heatingConsumption.push(
                            isNaN(record.heatingConsumption)
                                ? null
                                : round(record.heatingConsumption, 1),
                        );
                        acc.values.heatingConsumptionM2.push(
                            isNaN(record.heatingConsumptionM2)
                                ? null
                                : round(record.heatingConsumptionM2, 1),
                        );
                        // Cooling
                        acc.values.coolingConsumption.push(
                            isNaN(record.coolingConsumption)
                                ? null
                                : round(record.coolingConsumption, 1),
                        );
                        acc.values.coolingConsumptionM2.push(
                            isNaN(record.coolingConsumptionM2)
                                ? null
                                : round(record.coolingConsumptionM2, 1),
                        );
                        return acc;
                    },
                    {
                        values: {
                            waterConsumption: [],
                            waterConsumptionM2: [],
                            electricityConsumption: [],
                            electricityConsumptionM2: [],
                            heatingConsumption: [],
                            heatingConsumptionM2: [],
                            coolingConsumption: [],
                            coolingConsumptionM2: [],
                        },
                        expiration: now + EXPIRATION_PERIOD,
                    },
                );

                commit('updateConsumption', { ...consumption, key });
            }
            this.dispatch('showhideLoader', false);
        },
    },
    getters: {
        consumption: state => state.consumption,
        consumptionByKey: state => (key: string) => state.consumption[key],
        // waterConsumption: state => state.waterConsumption,
        // waterConsumptionM2: state => state.waterConsumptionM2,
        // electricityConsumption: state => state.electricityConsumption,
        // electricityConsumptionM2: state => state.electricityConsumptionM2,
        // heatingConsumption: state => state.heatingConsumption,
        // heatingConsumptionM2: state => state.heatingConsumptionM2,
        // coolingConsumption: state => state.coolingConsumption,
        // coolingConsumptionM2: state => state.coolingConsumptionM2,
        // consumptionsData: state => ({
        //     waterConsumption: state.waterConsumption,
        //     waterConsumptionM2: state.waterConsumptionM2,
        //     electricityConsumption: state.electricityConsumption,
        //     electricityConsumptionM2: state.electricityConsumptionM2,
        //     heatingConsumption: state.heatingConsumption,
        //     heatingConsumptionM2: state.heatingConsumptionM2,
        //     coolingConsumption: state.coolingConsumption,
        //     coolingConsumptionM2: state.coolingConsumptionM2,
        // }),
    },
};
